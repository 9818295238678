import React from "react"

const Cloud = ({ fill, height, width }) => {
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 128 128"
      x="0px"
      y="0px"
    >
      <title>network technology 01 final </title>
      <path d="M111.98358,27.49939Q112,27.034,112,26.566A26.816,26.816,0,0,0,85,0,27.20205,27.20205,0,0,0,64,9.87518,27.202,27.202,0,0,0,43,0,26.816,26.816,0,0,0,16,26.566q0,.46774.01642.93335A16.88329,16.88329,0,0,0,0,44.22638,16.71345,16.71345,0,0,0,10.47253,59.701,4,4,0,0,0,11.99,60H54V56H11.99A12.72208,12.72208,0,0,1,17,31.47168a13.01968,13.01968,0,0,1,3.68.52A21.58254,21.58254,0,0,1,20,26.566,23.07154,23.07154,0,0,1,64,17.3728,23.07154,23.07154,0,0,1,108,26.566a21.58254,21.58254,0,0,1-.68,5.4256,13.01968,13.01968,0,0,1,3.68-.52A12.72208,12.72208,0,0,1,116.01,56H74v4h42.01a4.0005,4.0005,0,0,0,1.51752-.299A16.71349,16.71349,0,0,0,128,44.22638,16.88329,16.88329,0,0,0,111.98358,27.49939Z"></path>
      <path d="M120,66H70V46h3a4,4,0,0,0,4-4h7.26331a8,8,0,1,0,0-4H77a4,4,0,0,0-4-4H55a4,4,0,0,0-4,4H45.73669a8,8,0,1,0,0,4H51a4,4,0,0,0,4,4h3V66H8a8.009,8.009,0,0,0-8,8V92a8.00909,8.00909,0,0,0,8,8H120a8.009,8.009,0,0,0,8-8V74A8.009,8.009,0,0,0,120,66ZM92,36a4,4,0,1,1-4,4A4,4,0,0,1,92,36ZM38,44a4,4,0,1,1,4-4A4,4,0,0,1,38,44Zm17-2V38H73v4Zm11,4V66H62V46Zm58,46a4,4,0,0,1-4,4H8a4,4,0,0,1-4-4V74a4,4,0,0,1,4-4H120a4,4,0,0,1,4,4Z"></path>
      <path d="M110,74a9,9,0,1,0,9,9A9.01015,9.01015,0,0,0,110,74Zm0,14a5,5,0,1,1,5-5A5,5,0,0,1,110,88Z"></path>
      <path d="M88,75a8,8,0,1,0,8,8A8.009,8.009,0,0,0,88,75Zm0,12a4,4,0,1,1,4-4A4,4,0,0,1,88,87Z"></path>
      <path d="M69,76a7,7,0,1,0,7,7A7.00794,7.00794,0,0,0,69,76Zm0,10a3,3,0,1,1,3-3A3,3,0,0,1,69,86Z"></path>
      <path d="M14,76a2.0001,2.0001,0,0,0-2,2V88a2,2,0,0,0,4,0V78A2.0001,2.0001,0,0,0,14,76Z"></path>
      <path d="M24,76a2.0001,2.0001,0,0,0-2,2V88a2,2,0,0,0,4,0V78A2.0001,2.0001,0,0,0,24,76Z"></path>
      <path d="M34,76a2.0001,2.0001,0,0,0-2,2V88a2,2,0,0,0,4,0V78A2.0001,2.0001,0,0,0,34,76Z"></path>
      <path d="M44,76a2.0001,2.0001,0,0,0-2,2V88a2,2,0,0,0,4,0V78A2.0001,2.0001,0,0,0,44,76Z"></path>
      <path d="M54,76a2.0001,2.0001,0,0,0-2,2V88a2,2,0,0,0,4,0V78A2.0001,2.0001,0,0,0,54,76Z"></path>
      <path d="M79,104v16a2.0001,2.0001,0,0,0,2,2h24.26331a8,8,0,1,0,0-4H83V104Zm34,12a4,4,0,1,1-4,4A4,4,0,0,1,113,116Z"></path>
      <path d="M45,118H22.73669a8,8,0,1,0,0,4H47a2.0001,2.0001,0,0,0,2-2V104H45Zm-30,6a4,4,0,1,1,4-4A4,4,0,0,1,15,124Z"></path>
      <path d="M62,104v8.26331a8,8,0,1,0,4,0V104Zm6,16a4,4,0,1,1-4-4A4,4,0,0,1,68,120Z"></path>
    </svg>
  )
}

export default Cloud
